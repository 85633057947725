import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import VuetablePaginationDropdown from 'vuetable-2/src/components/VuetablePaginationDropdown'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        VuetablePaginationDropdown,
        VuetablePaginationInfo,
        vSelect,
        axios,
    },
    data: function() {
        return {
            pageTitle: "Plan comptable general",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            listeCompte: "",

            crudform: {
                parent_id: "",
                num_compte: "",
                intitule: "",
                auxiliaires: "",
                observations: "",
            },
            fields: [
                /*{
                    name: VuetableFieldCheckbox,
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },*/
                /*{
                    name: 'parent_id',
                    title: 'PID',
                    isHeader: true
                },*/
                {
                    name: 'num_compte',
                    title: 'Compte',
                    sortField: 'num_compte',
                    width: "90px",
                    dataClass: "text-bold",
                },
                {
                    name: 'intitule',
                    title: 'Intitulé',
                    sortField: 'intitule'
                },
                {
                    name: 'auxiliaires',
                    title: 'Auxiliaires'
                },
                {
                    name: 'observations',
                    title: 'Observations'
                },
                {
                    name: 'actions',
                    width: "80px",
                    dataClass: "text-center"
                }

            ],
            sortOrder: [
                { field: 'num_compte', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hover',
                    loadingClass: 'loading',
                    ascendingIcon: 'fas fa-chevron-up',
                    descendingIcon: 'fas fa-chevron-down',
                    handleIcon: 'fas fa-spinner',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Compte': 'num_compte',
                'Intitulé': 'intitule',
                'Auxiliaires': 'auxiliaires',
                'Observations': 'observations',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            selectedCompte: [],

        }
    },
    methods: {
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(this.base_url+"/plancomptable/delete/"+rowData.id).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log(response.data.message);
                            that.setFilter();
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
            
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            // this.tabledata = this.$refs.vuetable.$data.tableData;
            // this.json_data = this.$refs.vuetable.$data.tableData;
            // $("#excel-download").trigger("click");
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.comptes = this.filtretexte;
            this.moreParams.sort = "num_compte|asc";
            
            console.log(this.moreParams);

            var that =  this;
            axios.get(that.base_url+"/plancomptable/fetchalldata?filter="+this.moreParams.filter
                                +"&criteriacol="+this.moreParams.criteriacol
                                +"&comptes="+this.moreParams.comptes
                                +"&sort="+this.moreParams.sort
                                , this.moreParams).then(function(response) {
                
                that.tabledata = response.data.data;
                that.json_data = response.data.data;

                // console.log(response.data);

                $("#excel-download").trigger("click");
            
            });
        },
        printBrouillard() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.comptes = this.filtretexte;
            this.moreParams.sort = "num_compte|asc";
            
            console.log(this.moreParams);

            var that =  this;
            axios.get(that.base_url+"/plancomptable/printpc?filter="+this.moreParams.filter
                                +"&criteriacol="+this.moreParams.criteriacol
                                +"&comptes="+this.moreParams.comptes
                                +"&sort="+this.moreParams.sort
                                , this.moreParams).then(function(response) {
                
                console.log(that.base_url+"/"+response.data);
                window.open(that.base_url+"/"+response.data);

            });
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                $(".vs__search").trigger("blur");
                $(".vs__search").focus();
                //console.log(filtre);
            //axios.get("/plancomptable/getall").then(response => {
                //this.listeCompte = response.data;
            });
        },
        addRow(){
            this.crudmodaltitle = "Ajouter un compte";
            this.crudform = {
                parent_id: "",
                num_compte: "",
                intitule: "",
                auxiliaires: "",
                observations: "",
            }
            this.initListCompte('');
            this.openModal();
        },
        editRow(rowData) {
            // this.initListCompte(rowData.num_compte);
            //alert("You clicked edit on" + JSON.stringify(rowData));
            // axios.get("/plancomptable/get/"+rowData.parent_id).then(response => {
            //     //console.log(response.data[0]);
            //     this.crudform = response.data[0];
            // });

            
            axios.get("/plancomptable/get/"+rowData.id).then(responsex => {
                //console.log(response.data[0]);
                this.crudform = responsex.data[0];
                this.selectedCompte = this.crudform.parent_info[0];
                this.listeCompte = this.crudform.parent_info;
                console.log(this.selectedCompte);

                this.crudmodaltitle = "Editer un compte";
                this.openModal();

                // axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+rowData.parent_id).then(response => {
                //     this.listeCompte = response.data.data;
                //     // $(".vs__search").trigger("blur");
                //     // $(".vs__search").focus();
                // });
    

            });


            // this.crudform = rowData;
            // console.log(rowData);
            
            // this.crudmodaltitle = "Editer un compte";
            // this.openModal();
        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/plancomptable/action", $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log("Enregistrement effectué avec succès!");
                            that.closeModal();
                            that.setFilter();
                            Vue.$toast.open({
                                message: 'Enregistrement effectué avec succès!',
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            

            
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        axios.get(this.base_url+"/plancomptable/showrecursive").then(response => {
            this.table = response.data;
        });

        this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        // this.initListCompte('');
        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}